import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const ContentPage = () => {
  const keyRef = 'upcloud';
  const provider = 'UpCloud';

  return (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="snapshots"
      title="UpCloud Storage Backup Service"
      description="Automate your UpCloud Storage backups. Set a schedule, a destination storage and let it work in peace."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">UpCloud</span> backups</>)}
      subTitle={(
        <>Automatically back up your all your UpCloud Storage.<br/>Recover your data in minutes, and focus on building incredible things.
        </>)}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename="brands/icons/upcloud-ico.png" alt="UpCloud backup" className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your UpCloud Backup" urlAtrr={{ sb_source: "website", sb_term: "upcloud-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename={`simplebackups-${keyRef}-snapshot-hero.png`} alt={`SimpleBackups ${provider} snapshot`}/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                title: (<>Flexible scheduling for UpCloud backups</>),
                description: (
                  <>
                    <p>
                      Unlock flexible scheduling & retention for your UpCloud Volume (Storage) backups.<br/>

                      <Ui.FeatureChecks items={[
                        'Control of when your backup will run', 'Unrestricted retention', 'Unlimited backup intervals']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-scheduling'
                  alt={`${provider} Backup custom scheduling`}
                  style={{maxWidth: 450}}
                />)
              },
              {
                title: (<>Storage & Volume backup</>),
                description: (
                  <>
                    <p>
                      Easily connect your UpCloud account(s) using your API Token.<br/>
                      Select the Storage you want to backup and where you want to store it, and you're done.
                      <Ui.FeatureChecks items={['Storage Backup', 'Unlimited connected accounts']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename={`simplebackups-app-backup-lifecycle.png`}
                  alt={`${provider} backup `}
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Backup service, the way it should be!</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">SimpleBackups is fully integrated with UpCloud, meaning all is configurable from an easy-to-use UI.</p>

            <Sections.FeatureSlider tags={["snapshot"]} />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about UpCloud Storage & Volume Backups?</>}
      text="Find out more and get started with our UpCloud Backup guides"
      tags={['snapshot']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['upcloud_what_is_snapshot', 'how_snapshot', 'snapshot_where']}/>

  </Layout.Layout>
)}

export default ContentPage
